import {
  apiGet,
  apiPost,
  apiPut,
  apiPatch,
  jsonApiRequestHeaders,
} from '../fetch';
import { apiUrl } from './helpers';
/**
 * ================================================================
 * API Endpoints (Grape)
 * ================================================================
 */

const headers = jsonApiRequestHeaders();

const endpoints = {
  getConsults: (params = {}) => apiGet(apiUrl('/consults'), params, headers),
  getConsult: (id, params = {}) =>
    apiGet(apiUrl(`/consults/${id}`), params, headers),
  createConsult: (body = {}) => apiPost(apiUrl('/consults'), body, headers),
  rescheduleConsult: (id, params = {}, body = {}) =>
    apiPut(apiUrl(`/consults/${id}/reschedule`), body, headers, params),
  cancelConsult: (id, params = {}, body = {}) =>
    apiPut(apiUrl(`/consults/${id}/cancel`), body, headers, params),
  getEmployees: (params = {}) => apiGet(apiUrl('/employees'), params, headers),
  getEmployee: (id, params = {}) =>
    apiGet(apiUrl(`/employees/${id}`), params, headers),
  getEmployeeCourses: (params = {}) =>
    apiGet(apiUrl('/employee_courses'), params, headers),
  getAvailableCoaches: (params = {}) =>
    apiGet(
      apiUrl('/booking/coach_profiles/available_coaches'),
      params,
      headers
    ),
  getBrowseCoaches: (params = {}) =>
    apiGet(apiUrl('/booking/coach_profiles/browse_coaches'), params, headers),
  createCoachProfileEvent: (params = {}) =>
    apiPost(apiUrl('/booking/coach_profiles/create_event'), params, headers),
  getAvailableSlots: (params = {}) =>
    apiPost(apiUrl(`/availability`), params, headers),
  claimOrganizationCoachingSlot: (params = {}) =>
    apiPost(apiUrl(`/organization_coaching_slots/claim`), params, headers),
  getOrganizations: (params = {}) =>
    apiGet(apiUrl('/organizations'), params, headers),
  getOrganization: (id, params = {}) =>
    apiGet(apiUrl(`/organizations/${id}`), params, headers),
  getSubmissions: (params = {}) =>
    apiGet(apiUrl('/submissions'), params, headers),
  getPrograms: (params = {}) => apiGet(apiUrl('/programs'), params, headers),
  getUserProgramJourney: (id, params = {}) =>
    apiGet(apiUrl(`/programs/${id}/user-journey`), params, headers),
  getUserProgramJourneyPro: (id, params = {}) =>
    apiGet(apiUrl(`/employee_programs/${id}/user-journey`), params, headers),
  getUserProgramsPro: (params) =>
    apiGet(apiUrl('/employee_programs'), params, headers),
  getTodos: (params = {}) => apiGet(apiUrl('/todos'), params, headers),
  getSubmission: (id, params = {}) =>
    apiGet(apiUrl(`/submissions/${id}`), params, headers),
  getMessages: (params = {}) => apiGet(apiUrl('/messages'), params, headers),
  getMessage: (id, params = {}) =>
    apiGet(apiUrl(`/messages/${id}`), params, headers),
  getMe: (params = {}) => apiGet(apiUrl('/users/me'), params, headers),
  markNoShow: (id, params = {}, body = {}) =>
    apiPut(apiUrl(`/consults/${id}/no_show`), body, headers, params),
  subscribeDailyMoments: (body = {}) =>
    apiPost(apiUrl('/campaigns'), body, headers),
  updateProfile: (body = {}) => {
    let bodyWithCookies = { ...body, cookies: headers['Cookie'] };

    return apiPut(apiUrl('/users/me'), bodyWithCookies, headers);
  },
  updateContactInfo: (body = {}) =>
    apiPut(apiUrl('/users/contacts'), body, headers),
  updatePassword: (body = {}) =>
    apiPut(apiUrl('/users/password'), body, headers),
  updateLanguage: (body = {}) =>
    apiPut(apiUrl('/users/language'), body, headers),
  bookingFlowStarted: (body = {}) =>
    apiPost(apiUrl('/consults/started'), body, headers),
  notificationViewed: (body = {}) =>
    apiPost(apiUrl('/notifications/viewed'), body, headers),
  updateConsult: (id, body = {}) =>
    apiPatch(apiUrl(`/consults/${id}`), body, headers),
  signupToDailymoment: (body = {}, resourceId) =>
    apiPost(
      apiUrl(`/employees/${resourceId}/sign_up_daily_moments`),
      body,
      headers
    ),
  trackEvent: (body = {}) => {
    // forces cookies sending to grape-api using request's body. Grape-Api uses a different cookie jar than Rails
    let bodyWithCookies = { ...body, cookies: headers['Cookie'] };
    return apiPost(apiUrl('/users/track_event'), bodyWithCookies, headers);
  },
  disconnectGcal: (params = {}) =>
    apiGet(apiUrl('/users/disconnect_gcal'), params, headers),
  fetchRescheduleWarning: (consultId) =>
    apiGet(apiUrl(`/reschedule/warning?consult_id=${consultId}`), headers),
  trackFairUseEvent: (body = {}) =>
    apiPost(apiUrl(`/reschedule/track`), body, headers),
  updateVideoSession: (body = {}) =>
    apiPost(apiUrl(`/zoom/video`), body, headers),
};

export default endpoints;

/**
 * Expose API functions to window for
 * convenient development & debugging
 */
if (__DEVELOPMENT__) {
  window.BravelyApi = endpoints;
}
